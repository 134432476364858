// --------------------------------------------------
// Variables
// --------------------------------------------------
@font-face {
  font-family: 'acherus_grotesque';
  src: url('/assets/fonts/acherusgrotesque-regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/acherusgrotesque-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'acherus_grotesque';
  src: url('/assets/fonts/acherusgrotesque-black-webfont.woff2') format('woff2'),
       url('/assets/fonts/acherusgrotesque-black-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

$fontTitle: 'mendl-serif-dusk', sans-serif;
$fontText: 'acherus_grotesque', sans-serif;
$fontCaption: $fontText;

$fontRoboto: $fontText;
$fontJosefin: $fontText;
$fontHero: $fontText;
$fontSaturday: $fontText;
$openSans: $fontText;
$crimson: $fontText;
$fontCocoGothic: $fontText;

/*
 *    Colors
 *    ------
 */
$black : #000;
$white : #fff;
$darkGray: #8C8C8C;
$darkerGray: #515151;
$lightGray: #D8D8D8;
$lighterGray: #F5F5F5;
$lighterGray2: #E5E5E5;

$light : #F8F6EF;

$colorAccent1: #4B342C;
$colorAccent2: #DA3732;
$lightColorAccent2: #FFD0CE;
$colorAccent3: #F8F6EF;
$colorAccent4: #FFD6A8;
$colorAccent5: #F1E9D4;
$colorAccent6: #f2e9d1;
$borderColor: $lightGray;

$colorGold: #c49e65;

$fontMainTitle: $fontJosefin;
$fontTitleBold: $openSans;
