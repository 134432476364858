.nutritionals-table-wrapper {
  padding: 30px;
  margin-top: 30px;
  background-color: $lighterGray;
  color: $darkerGray;

  @media only screen and (min-width: 768px) {

    padding: 30px;
  }

  .nutritionals-table {

    width: 100%;
    text-align: center;
    font-size: 14px;
    //letter-spacing: 1.29px;

    &.table2{

      margin-top: 30px;
    }

    thead {

      th {

        padding-bottom: 10px;
        border-bottom: 1px solid $borderColor;
      }
    }

    tbody {

      td, th {

        padding: 5px 0;

        &.bold{

          font-weight: bold;
        }

        &.sub-category{

          padding-left: 15px;
        }
      }

      .underlined td, .underlined th {

        border-bottom: 1px solid $borderColor;
      }

      .underlined + tr td, .underlined + tr th {

        padding-top: 20px;
      }
    }

    tfoot {

      font-size: 14px;
      line-height: 14px;

      td {

        padding-top: 30px;
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.8;
        color: #444444;

      }
    }
  }
}
