// --------------------------------------------------
// Footer
// --------------------------------------------------

.footer {
  color: $black;
}


.bloc-infolettre {
  width: 100%;
  z-index: 1;
  padding: 100px 50px;
  background: $black;
  //background-image: url('../images/newsletter-bg.png');
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  text-align: center;

  .title3{
    @extend .text-heading5;
    line-height: 33px;
    text-align: center;
    color: $colorAccent5;
  }

  i
  {
    position: relative;
    top: 7px;
    display: inline-block;
    color: $colorAccent2;
    font-size: 53px;
    font-weight: 100;
    line-height: 1;
    height: 48px;
      width: 48px;


    &.icon-infolettre {
      background: url('../assets/icons/newsletter.svg') no-repeat center center / 45px;

    }

    &.icon-arrow-right {
      background: url('../assets/icons/chevron.svg') no-repeat center center / 9px;
    }
  }

  .input-infolettre{
    position: relative;
    width: 80%;
    max-width: 350px;
    margin: 0 auto;
    padding: 0 0;

    .form-field{
      position: relative;
    }

    input[type="email"]{
      width: 100%;
      height: 65px;
      padding: 7px 7px 7px 28px;
      border: none;
      border-radius: 4px;
      outline: none;
      background: $darkGray;
      color: $colorAccent5;

      font-family: $fontText;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 180%;

      &::placeholder {
        color: $colorAccent5;
      }
    }

    [type="submit"]{
      position: absolute;
      top: 7px;
      right: 7px;

      width: 51px;
      height: 51px;

      border: none;
      outline: none;
       border-radius: 4px;
      // border-top-right-radius: 60px;
      // border-bottom-right-radius: 60px;
      // border-top-left-radius: 0;
      // border-bottom-left-radius: 0;
      overflow: hidden;

      color: #fff;
      background: $colorAccent2;
      text-transform: uppercase;

      i{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 100%;
        font-size: 28px;
        text-align: center;
        color: $white;
        padding-bottom: 0;
      }
    }

    .parsley-errors-list{
      position: absolute;
      left: 0;
      bottom: -18px;
      width: 100%;
    }

  }

  .js-news-message {
    display: none;

    p {
      letter-spacing: 3px;
      line-height: 31px;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 12px;
      color: $white;

      @media all and (min-width: 1099px) {

        font-size: 14px;
      }

      &.success{
        color: $light;
      }

      &.error{
        color: #f8901f;
      }
    }
  }

  @media all and (max-width: 1024px) {
    padding: 40px 20px;
    position: relative;
    bottom: 0;
  }

  @media all and (max-width: 799px) {

    .icon
    {
      width: 50px;
    }
  }

  @media all and (max-width: 599px) {

    .input-infolettre
    {
      width: 100%;
      input[type="email"]{
        width: 100%;
        padding: 15px;
        border: none;
        outline: none;
        // border-radius: 60px;
        outline: none;
        color: #181818;
        font-size: 15px;
        font-weight: 400;
      }
    }

  }

}


.site-content{
  padding-bottom: 442px;
  @media all and (max-width: 1024px) {
    padding-bottom: 0;
  }
}
.main-footer {
  z-index: 1000;

  display: flex;
  align-items: center;

  width: 100%;
  height: 50px;

  padding: 0 30px;

  background: $darkGray;
  color: $lightGray;

  // border-top: 1px solid #eee;

  @extend %clearfix;

  .icon-share
  {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
    transition: all 0.2s ease-in-out;
    background-size: cover;

    &:last-child {
      margin: 0;
    }

    &.icon-facebook {
      background-image: url('../assets/icons/facebook.svg');
    }

    &.icon-instagram {
      background-image: url('../assets/icons/instagram.svg');
    }

    &:hover
    {
      opacity: .8;
    }
  }


  .main-text{
    display: inline-block;

    margin: 0;

    @extend .text-caption;
    color: $lightGray;
    text-decoration: none;

    &.share-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 180%;
      margin-right: 20px;
    }

    @media all and (max-width: 499px){
      padding: 15px 0;
    }
  }

  .right{
    display: flex;
    align-items: center;

    margin-left: auto;

    .poc-link {
      margin-right: 20px;
      font-size: 16px;
    }
  }

  @media all and (max-width: 1024px) {
    position: relative;
  }

  @media all and (max-width: 850px) {
    .right
    {
      display: none;
    }
  }

  @media all and (max-width: 499px) {
    .left{
      float: none;
      width: 100%;
    }
    .right{
      display: none;
    }
    .main-text{
      width: 100%;
      text-align: center;
    }
  }

}
