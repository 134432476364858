
.switch-mobile
{
  width: 60px;
  height: 60px;
  overflow: hidden;
  background-color: $colorAccent2;
  border-radius: 30px;
  position: fixed;
  z-index: 10000000;
  right: 20px;
  bottom: 20px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 5px 10px 0px rgba(50,50,50,0.5);
  display: none;
  .open
  {
    width: 22px;
    margin-top: 22px;
    display: inline-block;
    div
    {
      width: 100%;
      height: 4px;
      background-color: #fff;
      margin-bottom: 2px;
    }
  }
  .close
  {
    display: none;
    p
    {
      font-size: 25px;
      margin-top: 10px;
      font-family: $fontJosefin;
      color: #fff;
    }
  }
}
@media all and (max-width: 1024px)
{
  .switch-mobile
  {
    display: block;
  }
}

.mobile-menu
{
  width: 100%;
  height: 100vh;
  background-color: $white;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 100000;
  text-align: center;

  transition: all 0.2s ease-in-out;

  &.active
  {
    left: 0;
    #search-input {
      width: 100%;
      position: relative;
      opacity: 1;
      right: 0;
      top: 0;

      input {
        display: block;
        width: 100%;
        border: 2px solid $colorAccent2;
        margin-bottom: 10px;
        color: $colorAccent2;
        padding: 8px 13px 8px 50px;
        font-size: 14px;
        border-radius: 100px;
        box-sizing: border-box;
        background: url('../assets/icons/search-red.svg') no-repeat left 15px center / 15px;
        outline: none;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: rgba($colorAccent2, .5);
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: rgba($colorAccent2, .5);
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: rgba($colorAccent2, .5);
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: rgba($colorAccent2, .5);
        }
      }
      button {
        opacity: 0;
      }
    }
  }

  .logo-mobile
  {
    width: 100%;
    max-width: 60px;
    display: inline-block;
    margin-top: 6vh;
  }

  .share {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  .icon-share
  {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: inline-block;
    float: left;
    margin: 0 10px 0 0;
    text-align: center;
    transition: all 0.2s ease-in-out;

    &.facebook {
      background: $colorAccent2 url('/assets/icons/facebook-white.svg') no-repeat center center /16px;
    }

    &.instagram {
      background: $colorAccent2 url('/assets/icons/instagram-white.svg') no-repeat center center /30px;
    }

    &:hover
    {
      background-color: $colorAccent2;
    }
    i
    {
      color: #fff;
      margin-top: 22px;
      display: inline-block;
    }
  }

  nav
  {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 15vw;
    a
    {
      display: block;
      width: 100%;
      // border: 2px solid $colorAccent2;
      margin-bottom: 10px;
      color: $colorAccent2;
      padding: 8px 13px;
      font-size: 14px;
      font-family: $fontJosefin;
      border-radius: 100px;
      text-decoration: none;

      transition: all 0.2s ease-in-out;

      &:hover
      {
        background-color: $colorAccent2;
        color: $white;
      }

      &.active
      {
        background-color: $colorAccent2;
        color: $white;
      }
    }
  }


}
