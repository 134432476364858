@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?tlwkon');
  src:  url('../fonts/icomoon.eot?tlwkon#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?tlwkon') format('truetype'),
    url('../fonts/icomoon.woff?tlwkon') format('woff'),
    url('../fonts/icomoon.svg?tlwkon#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// .icon-arrow-right:before {
//   content: "\e903";
// }
// .icon-infolettre:before {
//   content: "\e902";
// }
// .icon-instagram:before {
//   content: "\e901";
// }
// .icon-facebook:before {
//   content: "\e900";
// }
.icon-brand:before {
  content: "\e900";
}
.icon-social:before {
  content: "\e900";
}

