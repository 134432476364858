.site-btn {
  display: inline-block;
  width: fit-content;
  margin: 0 auto;
  padding: 14px 40px;
  text-decoration: none;
  font-family: $fontTitle;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  // color: $colorAccent5;
  color: $white;
  // background-color: $colorAccent2;
  background-color: $colorGold;
  border-radius: 4px;

  &:hover {
    opacity: .8;
    transition: opacity .5s;
  }

  &:not(:hover) {
    opacity: 1;
    transition: opacity .5s;
  }

  &.is-outlined {
    background: rgba($colorGold, 0);
    border: 1px solid $colorGold;
    color: $colorGold;
    transition: background .2s ease-in-out;

    &:hover {
      background: rgba($colorGold, .2);
      opacity: 1;
    }

    &.color1 {
      background: transparent;
      border: 1px solid $black;
      color: $black;
      transition: background .2s ease-in-out;

      &:hover {
        background: rgba($black, .2);
        opacity: 1;
      }
    }
  }

  &.color2 {
  	// background-color: $colorAccent2;
    //color: $white;
    color: $white;
    background-color: $colorGold;

    &:hover {
      background-color: $colorGold;
      transition:  .5s;
    }

    &:not(:hover) {
      opacity: 1;
      transition:  .5s;
    }
  }

  &.color1 {
    background-color: $black;

    &:hover {
      background-color: $colorAccent5;
      color: $black;
      transition:  .5s;
    }

    &:not(:hover) {
      opacity: 1;
      transition:  .5s;
    }
  }

  &.gold {
    background-color: $colorGold;
    color: $white;
  }
}

button{
  &:hover {
    cursor: pointer;
    opacity: .8;
    transition: opacity .5s;
  }

  &:not(:hover) {
    opacity: 1;
    transition: opacity .5s;
  }
}
