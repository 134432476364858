// --------------------------------------------------
// Base
// --------------------------------------------------

* {
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: auto;
  font-size: 62.5%;
}

html {
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: $openSans;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.8;
  @media (max-width: 1024px) {
    padding-bottom: 0;
  }
}

// Helpers
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.content{
  // background-color: $white;
}

.site-max-width{
  width: 100%;
  max-width: 1260px;
  margin: 0 auto !important;
  padding: 0 45px;
}

.text-heading1 {
  font-family: $fontText;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  line-height: 110%;
  color: $black;
}

.text-heading2 {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 98px;
  color: $black;
}

.text-heading3 {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 87px;
  color: $black;
}

.text-heading4 {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;
  color: $black;
}

.text-heading5 {
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: $black;
}

.text-regular {
  font-family: $fontText;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  color: $black;
}

.text-subtitle {
  font-family: $fontCaption;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: $black;
}

.text-caption {
  font-family: $fontCaption;
  color: $black;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
