// --------------------------------------------------
// Header
// --------------------------------------------------

.header {
	position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
	align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 30px;

  background-color: $black;

  z-index: 2000;
  transition: all .2s ease-in-out;

	box-shadow: 0px 1px 9px rgba(75, 52, 44,.4);

  @media (max-width: 469px) {
    padding: 0 15px;
  }
}

.left-header .logo {
  max-width: 220px;
  margin-top: 40px;

  @media screen and (max-width: 469px) {
    // margin-top: 30px;
    //width: 100px;
    //margin-left: 20px;
  }
}

.right-header {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.right-header a {
	display: flex;
	flex-direction: row;
	align-items: center;

  padding: 0 15px;

  @extend .text-subtitle;
  color: $colorAccent5;
  text-decoration: none;
  text-align: center;
  transition: color ease-out .2s;

  &.active, &:hover {
    color: $colorGold;
  }

  &.language {
    @extend .text-caption;
    color: $colorAccent5;
    font-weight: normal;
    padding-right: 30px;

    &:hover {
      color: $colorGold;
    }
  }


  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.right-header a.cta {
	display: flex;
	flex-direction: row;
	align-items: center;
  justify-content: center;

  padding: 14px 40px;
  background-color: $colorAccent2;

  border: 1px solid transparent;
  border-radius: 4px;

  font-family: $fontTitle;
	color: $colorAccent5;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: normal;
  text-transform: none;
	text-align: center;

  @media (max-width: 470px) {
    padding: 14px 20px;
  }

	&:hover {
    opacity: .6;
    transition: opacity .5s;
  }

  &:not(:hover) {
    opacity: 1;
    transition: opacity .5s;
  }
}

.menu {
  position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.search {
  width: 50px;
  height: 70px;
  padding: 0 13px;
  cursor: pointer;

  @media screen and (max-width: 1350px) {
    padding: 25px 0 25px 10px;
  }

  img {
    width: 17px;
    cursor: pointer;

    &.icon_close {
      display: none;
    }
  }
}

#search-input {
  position: absolute;
  right: 10px;
  top: 11px;
  width: 0;
  opacity: 0;
  z-index: -1;
  transition: all ease-out .2s;

  &.open {
    width: 480px;
    opacity: 1;
    z-index: 1;

    transition: all ease-out .2s;

    @media screen and (max-width: 1080px) {
      width: 420px;
    }
    @media screen and (max-width: 1024px) {
      right: 350px;
    }

    @media screen and (max-width: 980px) {
      width: 400px;
    }

    @media screen and (max-width: 940px) {
      width: 350px;
    }

    @media screen and (max-width: 890px) {
      width: 300px;
    }

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  input {
    outline: none;
    float: left;
    width: calc(100% - 40px);
    padding: 5px 10px 5px 15px;
    border: 0;
    border-radius: 30px 0 0 30px;
    font-size: 17px;
    height: 42px;
		border: 1px solid $colorAccent2;

    @-moz-document url-prefix() {
      width: calc(100% - 53px);
    }
  }

  button {
    width: 30px;
    height: 43px;
    float: left;
    border: none;
    border-radius: 0 30px 30px 0;
    font-size: 18px;
    padding: 0 20px;
    line-height: 25px;
    // margin-top: 10px;
    cursor: pointer;
    background: $colorAccent2 url('../assets/icons/search-white.svg') no-repeat center center / 15px;
  }
}
