// --------------------------------------------------
// Content
// --------------------------------------------------

.content {

  &.blog-content-wrapper {
    background: $colorAccent3;
    padding-bottom: 100px;

    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }
}

.main {
	margin-top: 70px;
	position: relative;
	z-index: 1000;

	@media all and (max-width: 1024px) {
	  padding-bottom: 0px;
	}
}
