.hero {
  position: relative;
  z-index: 999;
  overflow: hidden;
  min-height: 500px;
  background: $light;

  .hero-video {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    background-size: cover;
    background-color: rgb(0, 0, 0);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: none;

    video {
      margin: auto;
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      visibility: visible;
      opacity: 1;
      width: 1922px;
      height: auto;
    }
  }

  // Option: Background Image
  &.with-image {
    .hero-image {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: cover;

      // &:after {
      //   content: '';
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      //   background-color: rgba($white, .5);
      // }

      &.for-desktop {
        position: relative;
        inset: auto;
        flex: 1;
        width: 100%;

        @media screen and (max-width: 767px) {
          display: none;
        }

        img {
          display: block;
          width: 100%;
        }
      }

      &.for-mobile {
        display: none;
        position: relative;
        inset: auto;
        flex: 1;
        width: 100%;

        @media screen and (max-width: 767px) {
          display: block;
        }

        img {
          display: block;
          width: 100%;
        }
      }
    }

    .hero-content {
      position: relative;
    }
  }

  .scroll {
    width: 100%;
    max-width: 25px;
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -15px;
  }

  .overlay-opacity {
    position: absolute;
    height: 100%;
    width: 100%;
    min-height: calc(85vh - 85px);
    background-color: rgba($black, .45);

    &.fullheight {
      min-height: calc(100vh - 85px);
    }
  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    display: flex;
    flex-direction: column;

    &.hero-home {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      padding-block: 50px;

      .title1 {
        @extend .text-heading1;
        color: $colorAccent1;
        max-width: none;

        @media (max-width: 767px) {
          font-size: 60px;
        }
      }

      .title3 {
        color: $colorAccent1;
      }

      .site-btn {
        margin-top: 30px;
      }
    }

    .title1 {
      font-size: 62px;
      margin: 0 auto 30px auto;
      max-width: 1020px;
      color: $colorAccent5;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 2.5px;
      text-align: center;
      font-family: $fontTitle;

      span {
        color: $colorAccent1;
      }

    }

    .site-btn {
      margin-top: 10px;

      @media (max-width: 767px) {
        margin-top: 30px;
      }
    }

    .site-hr {
      background: $white;
      margin-bottom: 15px;
    }

    .title2 {
      font-weight: 500;
      font-size: 18px;
      color: $colorAccent1;
    }

  }

  @media all and (max-width: 767px) {

    .content {

      &.hero-home {
        padding-block: 30px;

        .title1 {
          margin-bottom: 20px;
        }
      }

      .title1 {
        margin: 0;
        font-size: 45px;
        line-height: 1.5;
        padding: 0;
        margin-bottom: 20px;
        max-width: 540px;
      }

      .tagline {
        font-size: 22px;
      }

      .title2 {
        font-size: 16px;
      }

    }

    img {

      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }

    }

  }

  @media all and (max-width: 599px) {

    .content {

      .site-hr {
        margin-bottom: 10px;
      }

      .title1 {
        margin: 0;
        font-size: 45px;
        line-height: 1.2;
      }

      .title2 {
        font-size: 14px;
      }

    }

  }

  @media all and (max-width: 374px) {

    .title2 {
      font-size: 14px;
    }

  }

}

.hero.fullheight {
  min-height: calc(85vh - 85px);
  background: url('../images/hero.jpg') $light center center no-repeat;
  background-size: cover;
  background-position: center center;

  &.realfullheight {
    min-height: calc(100vh - 85px);
  }

  @media all and (max-width: 1000px) {
    background-size: cover;
    &.chine {
      background-size: cover;
    }
  }
}

.hero.hero-title {
  min-height: calc(85vh - 85px);
  background: url('../images/hero.jpg') $light center center no-repeat;
  background-size: 100%;
  background-position: bottom;
  &.chine {
    background: url('../images/hero-concours.jpg') $light center center no-repeat;
    background-size: 100%;
  }
  @media all and (max-width: 1000px) {
    background-size: cover;
    &.chine {
      background-size: cover;
    }
  }
}


.hero-sub-title{
  @extend .text-subtitle;
  color: $colorAccent5;
  margin: 0;
}

.recettes {
  .hero-title {
    font-family: $fontHero;
    background-image: url('../images/hero-recettes.png');
  }
}

.produits {
  .hero-title {
    font-family: $fontHero;
    background-image: url('../images/hero-produits.png');
  }
}

.contact {
  .hero-title {
    font-family: $fontHero;
    background-image: url('../images/hero-contact.png');
  }
}

.hero-title {
  position: relative;
  z-index: 1000;
  overflow: hidden;
  min-height: 402px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  @media all and (max-width: 1024px) {
    min-height: 250px;
  }

  @media all and (max-width: 1000px) {
    background-size: cover;
  }

  &.hero-entry {
    min-height: 600px;

    .overlay-opacity {
      height: 100%;
      min-height: 600px;
    }

    .content {
      margin-top: -65px;
    }
  }

  .author-wrapper {
    position: absolute;
    bottom: 105px;
    left: 0;
    right: 0;
    z-index: 2;

    .author-photo {
      width: 80px;
      height: 80px;
      background: black no-repeat center;
      border-radius: 50%;
      margin: auto;
    }

    .blog-date {
      margin: 20px auto 0;
      text-align: center;

      @extend .text-subtitle;
      color: $colorAccent5;
    }
  }

  &.hero-recipes,
  &.hero-products,
  &.hero-contact,
  &.hero-blog {
    background-position: center;

    @media (max-width: 767px) {
      padding-bottom: 50px;
    }
  }

  &.hero-recipes,
  &.hero-products {
    @media (max-width: 767px) {
      position: relative;
      top: 0;
      transform: none;
    }
  }

  &.hero-recipes {
    min-height: 220px;

    @media all and (max-width: 1024px) {
      min-height: 280px;
    }

    .content {
      transform: translateY(-25%);
    }
  }

  &.hero-about {
    background-position: 100% 70%;
  }

  img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    &.mobile {
      display: none;
    }

  }

  .overlay-opacity {
    position: absolute;
    height: 100%;
    width: 100%;
    min-height: 400px;
    background-color: rgba($black, .45);


    @media (max-width: 767px) {
      position: absolute;
      top: 0;
      height: 100%;
    }

    @media all and (max-width: 1024px) {
      min-height: 250px;
    }
  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    .title1 {
      @extend .text-heading4;
      margin: 0;
      // color: $colorAccent5;
      color: $white;

      span {
        color: $colorAccent1;
      }

    }

  }

  .back-link {
    position: absolute;
    margin: 51px 0 0 68px;
    z-index: 2;

    @media (max-width: 767px) {
      position: relative;
      margin: 35px 0 20px 30px;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;

      @extend .text-regular;
      color: $colorAccent5;
      text-decoration: none;
      color: $white;

      &:before {
        content: "";
        position: relative;
        display: block;
        width: 8px;
        height: 12px;
        margin-right: 12px;
        background: transparent url('/assets/icons/back-arrow.svg') no-repeat center center;
        background-size: cover;
      }
    }
  }

  @media all and (max-width: 767px) {

    img {

      &.desktop {
        display: none;
      }

      &.mobile {
        display: block;
      }

    }

  }

  @media all and (max-width: 599px) {

    .content {

      .title1 {
        margin: 0;
        font-size: 42px;
        line-height: 1.2;
      }

    }

  }

}
