.bloc-arguments-2{
  padding: 100px 0;
  text-align: center;
  background: $white;
  position: relative;
  z-index: 990;

  &.no-padding
  {
    padding: 0;
  }

  &.background-recipes {
    background: $black;
    //background-image: url(../images/background-flower.png);
    background-size: cover;
    background-attachment: fixed;
    background-position: 80%;

    .title3 {
      color: $colorAccent5;
    }
    .content{
      .title4 {
        // color: $colorAccent3;
        color: $colorAccent4;
      }
      .title3 {
        color: $colorAccent5;
      }
    }
  }

  &.recipes {
    background-color: $colorAccent3;

    .title3 {
      color: $black;
    }
  }

  .row{
    padding: 4vw;
  }

  .site-btn-center
  {
    .color-light
    {
      color: #fff !important;
    }
  }

  .title3 {
    @extend .text-heading4;
    margin-top: 0;
    color: $darkerGray;
  }

  .arguments{
    padding: 0 0 0 0;
    margin-bottom: 20px;
  }

  .argument{
    text-align: center;
    margin: 0 .5% 25px .5%;

  }

  &.medium
  {
    background-color: #F1F1F1;
  }

  &.dark{
    background-image: url('../images/dark-background.jpg');
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    @media all and (max-width: 1024px) {
      background-attachment: scroll;
    }
    .title3
    {
      color: #fff;
    }
    .bloc-list
    {
      .content
      {
        .title4
        {
          color: $colorAccent3;
        }
        .title3.text
        {
          color: #fff;
        }
      }
    }
  }

  @media all and (max-width: 1429px) {
    .main-text{
      font-size: 13px;
      line-height: 24px;
    }
  }

  @media all and (max-width: 1249px) {
    // min-height: 650px;

    .title4
    {
      font-size: 14px;
    }
  }

  @media all and (max-width: 1024px) {
    padding: 40px 20px;
  }

  @media all and (max-width: 1119px) {
    .title4
    {
      // min-height: 53px;
    }

    .main-text{
      line-height: 1.6;
    }

    .bloc-list{
      .content{
        padding: 40px 10%;
      }
    }

  }

  @media all and (max-width: 767px) {
    .title4,
    .main-text
    {
      min-height: inherit;
    }
  }

  @media all and (max-width: 599px) {

    // .title3
    // {
    //   font-size: 18px;
    // }
    .arguments
    {
      padding: 0 0;
    }
  }

}

.bloc-list
{
  .top
  {
    overflow: hidden;
    position: relative;
    background-color: $colorAccent1;

    .preparation
    {
      position: absolute;
      top: 12px;
      left: 12px;
      z-index: 100;
      margin: 0;
      display: inline-block;
      background-color: $colorAccent2;
      font-size: 12px;
      color: #fff;

      a{
        color: $colorAccent3;
      }
    }
    .portions
    {
      position: absolute;
      top: 42px;
      left: 12px;
      z-index: 100;
      margin: 0;
      padding: 0;
      display: inline-block;
      background-color: $colorAccent3;
      padding: 1px 5px;
      font-size: 12px;
      color: #fff;
    }
    .play
    {
      width: 100%;
      max-width: 60px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -30px;
      margin-left: -30px;
      z-index: 100;
    }
    .thumb
    {
      display: block;
      width: 100%;
      height: auto;
      transform: scale(1.01);
      transition: all 0.5s ease-in-out;
      opacity: 1;
      &:hover
      {
        transform: scale(1.1);
        opacity: 0.8;
      }
    }
  }
  .content
  {
    padding: 40px 15% 0 15%;

    @media all and (max-width: 560px){
      padding: 20px 15% 20px 15%;
    }

    .title4{
      font-family: Josefin Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 180%;
      text-align: center;
      color: $colorAccent3;
      text-transform: lowercase;
    }

    .title3.text{
      font-size: 16px;
      line-height: 1.5;
      font-family: $fontTitleBold;
      font-weight: 100;
      letter-spacing: 2px;
      text-align: center;
      color: $colorAccent1;
    }
  }
}

.aside-list
{
  display: flex;
  position: relative;
  z-index: 1000;
  width: 100%;
  background: $colorAccent3;

  &.blog-list {
    background: $white;
  }

  @media all and (max-width: 900px){
    display: block;
  }

  .sub-menu
  {
    display: table-cell;
    width: 25%;
    background-color: #fff;
    vertical-align: top;

    @media all and (max-width: 900px){
      display: block;
      width: 100%;
    }

    .show-hide-categories
    {
      width: 100%;
      // padding: 10px 0px 23px 25px;
      height: 56px;
      padding: 10px 20px;
      background-color: $colorAccent1;
      cursor: pointer;
      display: none;

      @media all and (max-width: 900px){
        display: flex;
        align-items: center;
      }

      @extend %clearfix;

      p
      {
        margin: 0;
        padding: 0;

        font-family: $fontTitle;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 1px;
        color: #fff;
        display: inline-block;
      }

      .name
      {
        float: left;
        font-family: $fontJosefin;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 1.71px;
        line-height: 19px;
        padding:  0;
      }

      .plus
      {
        font-size: 44px;
        margin-left: auto;
        margin-top: -8px;
        line-height: 44px;
      }

      .moins
      {
        font-size: 44px;
        line-height: 44px;
        margin-top: -12px;
        display: none;
        margin-left: auto;
      }

      &.open
      {
        .plus
        {
          display: none;
        }
        .moins
        {
          display: block;
        }
      }


    }

    .sub-menu-link-wrap
    {
      border-right: 1px solid #F1F1F1;

      @media all and (max-width: 900px){
        display: none;
      }
    }

    .sub-menu-link
    {
      width: 100%;
      display: block;
      padding: 30px 25px;
      border-bottom: 1px solid #F1F1F1;

      font-family: $fontCaption;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      color: $darkerGray;
      font-size: 14px;
      letter-spacing: 1px;

      transition: all 0.2s ease-in-out;

      background-image: url('../assets/icons/arrow-black.svg');
      background-position: 93% center;
      background-repeat: no-repeat;
      background-size: 8px;

      @media all and (max-width: 900px){
        background-position: 95% center;
      }

      &.active,
      &:hover
      {
        background-color: $colorAccent3;
      }

    }
  }

  .list-wrap
  {
    @extend .site-max-width;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start ;
    align-items: stretch;

    min-height: 500px;
    padding: 100px 45px;

    @media all and (max-width: 560px){
      padding: 70px 15px 15px;
    }

    &.recipes{
      background-color: $colorAccent3;
      max-width: 100%;
    }

    @extend %clearfix;

    .bloc-list
    {
      display: inline-block;
      width: 33.33%;
      float: left;
      padding: 5px;
      @media all and (max-width: 1024px){
        width: 50%;
      }
      @media all and (max-width: 560px){
        width: 100%;
      }
      .title3.text
      {
        font-size: 14px;
      }
    }

    .search-title {
      width: 100%;
      text-transform: uppercase;
      color: #333;
      font-weight: 400;
      letter-spacing: 2px;
    }
    .search-info {
      width: 100%;
      letter-spacing: 1px;
    }
  }
}

.listWrap{
  @extend .site-max-width;
  display: flex;
  flex-wrap: wrap;
  padding: 0 30px;
  justify-content: center;

  @extend %clearfix;

  @media (max-width: 767px) {
    padding: 0;
  }

  &.centered{
    justify-content: center;
  }

  &.home {
    margin: 0 120px 10px 120px;

    @media(max-width: 1024px) {
      justify-content: center;
      margin: 0 80px;
    }

    @media(max-width: 600px) {
      margin: 0;
    }

  }
  &.home-products{
    margin-bottom: 30px !important;
  }
}

.hero-recette {
  position: relative;
  z-index: 1000;
  overflow: hidden;
  min-height: calc( 85vh - 85px );
  background: url('../images/hero.jpg') $light center center no-repeat;
  background-size: 100%;
  background-position: center center;
  @media all and (max-width: 1000px){
    background-size: cover;
  }

  .overlay-opacity
  {
    position: absolute;
    height: 100%;
    width: 100%;
    min-height: calc( 85vh - 85px );
    background-color: $colorAccent1;
    opacity: 0.45;
  }

  .credit
  {
    color: #fff;
    font-size: 10px;
    margin: 0;
    padding: 0;

    position: absolute;
    right: 15px;
    bottom: 15px;

    z-index: 100;
  }

  .print
  {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;
    p
    {
      display: inline-block;
      float: left;
      margin: 0;
      padding: 0;
      color: #fff;
      font-family: $fontTitle;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 1px;
      margin-left: 10px;
      margin-top: 1px;
    }
    img
    {
      width: 100%;
      max-width: 25px;
      display: inline-block;
      float: left;
    }
  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    .title1 {
      font-size: 42px;
      margin: 0 auto;
      max-width: 740px;
      color: #fff;
      font-weight: 700;
      line-height: 1.5;
      letter-spacing: 2px;
      font-family: $fontTitle;
      position: relative;
      top: 30px;

      span {
        color: $colorAccent1;
      }

    }

    .tool-bar
    {
      display: inline-block;
      list-style-type: none;
      position: relative;
      top: 80px;

      border-radius: 3px;
      border: 2px solid $colorAccent2;

      margin: 0;
      padding: 0;

      @media all and (max-width: 900px){
        top: 30px;
        max-width: 300px;
      }

      @extend %clearfix;

      li
      {
        display: inline-block;
        float: left;
        min-width: 225px;
        text-align: center;

        border-right: 2px solid $colorAccent2;

        padding: 5px 15px;

        @media all and (max-width: 900px){
          width: 100%;
          border-right:0;
          border-bottom: 2px solid $colorAccent2;
          &:last-child
          {
            border-bottom: 0;
          }
        }

        &:last-child
        {
          border-right: 0;
        }


        @extend %clearfix;

        img
        {
          display: inline-block;
          width: auto;
          height: 30px;
          position: relative;
          top: 10px;
          &.portions-icon
          {
            height: 23px;
            top: 6px;
          }
        }

        p
        {
          display: inline-block;
          color: #fff;
          font-family: $fontTitle;
          text-transform: uppercase;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1px;
          margin-left: 10px;
        }
      }
    }


  }

  @media all and (max-width: 767px) {

    .content {

      .title1 {
        font-size: 24px;
        line-height: 1.5;
        padding: 0;
        margin-bottom: 20px;
        max-width: 540px;
      }

    }

  }

  @media all and (max-width: 599px) {

    .content {

      .title1 {
        font-size: 42px;
        line-height: 1.2;
      }

    }

  }

}

.recette-wrap
{
  width: 100%;
  background-color: #fff;
  padding-bottom: 50px;
  position: relative;
  z-index: 1000;

  .recette-image {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 50px 25px 0 25px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .recette-data
  {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 25px 50px 25px;
    background-color: #fff;


    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    line-height: 30px;

    &:first-of-type{
      padding-top: 50px;
    }

    @media all and (max-width: 767px){
      flex-direction: column;
      padding: 25px 15px;
      font-size: 16px;
      line-height: 28px;
    }


    @extend %clearfix;

    .article-title{
      background-color: $light;
      color: $colorAccent1;
      margin: 0;
      padding: 15px 20px;
      font-weight: 400;
      text-transform: uppercase;
    }

    p,
    p span,
    .main-text,
    ul li
    {
      @extend .text-regular;

      color: rgba(0, 0, 0, 0.7);
      margin: 0 0 20px;
    }

    ul li{
      margin: 0;
      padding: 0;
    }

    .sub-title,
    h1,
    h2,
    h3,
    h4
    {
      @extend .text-subtitle;

      color: $darkerGray;
      margin: 0;
    }

    img
    {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 20px 0;
    }

    .aside-ingredients {
      width: 40%;
      display: inline-block;
      float: left;
      margin-right: 25px;

      @media all and (max-width: 767px){
        width: 100%;
      }
    }

    .ingredients
    {
      background-color: #F1F1F1;

      .recette-sub-header{
        padding: 30px;
        // background-color: $colorAccent2;
        background-color: $colorGold;

        strong{
          font-weight: bold;
        }

        h2
        {
          @extend .text-subtitle;
          color: $white;
          margin: 0;
          font-weight: 600;
        }
      }
      ul
      {
        background-color: $lighterGray;
        padding: 30px 32px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 15px;
          font-weight: 400;
          list-style-type: none;
          position: relative;
          text-align: left;
          color: rgba(0, 0, 0, 0.7);
          a
          {
            color: $colorGold;
          }
        }
        li:before
        {
          content: '';
          display: inline-block;
          width: 13px;
          height: 13px;
          background-image: url('../images/check.png');
          background-size: 13px;
          position: absolute;
          top: 5px;
          left: -25px;
        }
        li.is-title{
          position: relative;
          left: -25px;
          padding: 15px 0 5px 0;
          margin-bottom: 30px;
          border-bottom: 1px solid #000;

          &:before{
            display: none;
          }

          h1, h2, h3, h4, h5, h6{
            text-transform: uppercase;
            padding: 0;
            margin: 0;
          }
        }

        @media all and (max-width: 767px){
          padding: 15px;
        }
      }


    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 5px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: white;
        border-radius: 4px;
        border: 1px solid $lightGray;

        transition: background .3s ease-in-out;
      }

      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
          background-color: $lightGray;
      }

      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
          background-color: $colorAccent2;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
          content: "";
          position: absolute;
          display: none;
      }

      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
          display: block;
      }

      /* Style the checkmark/indicator */
      .container .checkmark:after {
          left: 8px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
      }
    }

    .preparation
    {
      width: 60%;
      display: inline-block;
      float: left;
      // margin-left: 15px;

      @media all and (max-width: 767px){
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
      }

      strong{
        font-weight: bold;
      }

      .preparation-content {
        padding: 30px;
        background: $lighterGray;
      }

      .recette-sub-header
      {
        padding: 30px;
        background-color: rgba($black, .6);

        h2
        {
          @extend .text-subtitle;
          color: $white;
          font-weight: 600;
          margin: 0;
        }

      }
      ol
      {
        list-style-type: decimal;
        padding: 0 25px 0 30px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 15px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
          a{
            color: $colorAccent3;
          }
        }
      }
      ul+h1,
      ul+h2,
      ul+h3,
      ul+h4,
      ul+h5,
      ul+h6{
        padding: 0 25px 0 0;
        line-height: 1.4;
        margin-bottom: 0;
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        border-bottom: 1px solid #000;
        text-transform: uppercase;
        a{
          color: $colorAccent3;
        }
      }
      ul+p b{
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        padding: 0;
        margin-left: 15px;
        a{
          color: $colorAccent3;
        }
      }
      .videoWrapper
      {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
        margin-left: 10px;
      }
      .videoWrapper iframe
      {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .recette-sub-header
    {
      background-color: $colorAccent1;
      padding: 15px 20px;
      color: #fff;
      h2
      {
        width: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        font-family: $fontTitle;
      }
    }

  }
}

.produit-wrap
{
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 1000;

  .produit-data
  {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    text-align: justify;
    padding: 0 25px 50px 25px;
    background-color: #fff;


    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    line-height: 30px;

    &:first-of-type{
      padding-top: 50px;
    }

    @media all and (max-width: 767px){
      padding: 25px 15px;
      font-size: 16px;
      line-height: 28px;
    }


    @extend %clearfix;

    .article-title{
      background-color: $light;
      color: $colorAccent1;
      margin: 0;
      padding: 15px 20px;
      font-weight: 400;
      text-transform: uppercase;
    }

    p,
    p span,
    .main-text,
    ul li
    {
      font-family: $fontText;
      color: rgba(0, 0, 0, 0.7);
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 20px;
      @media all and (max-width: 767px){
        font-size: 16px;
        line-height: 28px;
      }
    }

    ul li{
      margin: 0;
      padding: 0;
    }

    .sub-title,
    h1,
    h2,
    h3,
    h4
    {
      color: $colorAccent1;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    img
    {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      margin: 20px 0;
    }

    .produit-photo
    {
      position: relative;
      margin-right: 20px;
      width: calc(40% - 20px);
      // border: 1px solid rgba(0,0,0,0.2);
      display: inline-block;
      float: left;
      padding: 60px 40px 40px 40px;

      background: $lighterGray;

      @media all and (max-width: 767px){
        width: 100%;
      }
      .canvas{
        position: relative;
        width: 100%;
        max-width: 315px;
        height: 100%;
        margin: auto;
      }
      img
      {
        width: 100%;
        margin: 0;
        transform: scale(1.1);
      }
    }

    .preparation
    {
      width: 60%;
      display: inline-block;
      float: left;
      padding-left: 5px;

      @media all and (max-width: 767px){
        width: 100%;
        padding-left: 0;
        margin-top: 15px;
      }
      .description-data
      {
        padding: 25px;
        margin-bottom: 30px;
        background-color: $lighterGray;

        strong{
          font-weight: bold;
        }

        p
        {
          color: $darkerGray;
          font-family: $fontText;
          font-size: 14px;
          line-height: 180%;
          margin: 0;

          a{
            color: $colorAccent3;
          }
        }

        h2
        {
          margin-top: 25px;
          letter-spacing: 1px;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 500;
          font-family: $fontTitle;
        }
        .show-overlay-form
        {
          display: block;
          margin-top: 25px;

          color: $colorAccent1;
          font-family: $fontTitle;
          font-weight: 500;
          font-size: 15px;
          letter-spacing: 1px;
          line-height: 30px;
          text-transform: uppercase;
          text-decoration: none;
        }
      }

      .site-btn{
        margin-bottom: 0px;
      }

      .produit-sub-header
      {
        padding: 30px;
        background-color: $colorGold;

        h2
        {
          color: $white;
          margin: 0;
          font-family: $fontCaption;
          font-size: 14px;
          line-height: 17px;
          font-weight: 600;
          letter-spacing: 0.1em;
        }

      }
      ul
      {
        list-style-type: decimal;
        padding: 25px 25px 15px 35px;
        margin: 0;
        li
        {
          line-height: 1.4;
          margin-bottom: 25px;
          font-size: 15px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.7);
        }
      }
    }

    .produit-sub-header
    {
      background-color: $colorAccent1;
      padding: 15px 20px;
      color: #fff;
      h2
      {
        width: 100%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        font-family: $fontTitle;
      }
    }

    .site-btn-wrapper {
      display: flex;
      gap: 20px;

      .site-btn {
        margin-inline: 0;
      }

      @media screen and (max-width: 699px) {
        flex-direction: column;
        gap: 10px;

        .site-btn {
          width: 100%;
        }
      }
    }

  }
}


.show-overlay-form
{
  margin-top: 25px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  font-family: $fontTitle;
  color: $colorGold;
  display: block;
}

.overlay-form
{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200000;
  transition: 0.2s ease-in-out;
  visibility: hidden;

  .form-wrap
  {
    display: block;
    width: 380px;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    padding: 45px 55px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -225px;
    margin-left: -190px;
    z-index: 10;
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s;

    @media all and (max-width: 600px){
      width: 300px;
      margin-left: -150px;
      margin-top: -240px;
      padding: 35px;
    }

    img
    {
      width: 100%;
    }

    .close
    {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 0;
      margin: 0;
      font-weight: 600;
      color: $lightGray;
      cursor: pointer;
      font-size: 20px;
    }
  }

  .overlay-filter
  {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9;
    transition: 0.2s ease-in-out;
    background-color: $lightGray;
    opacity: 0;
  }

  &.active
  {
    visibility: visible;
    .overlay-filter
    {
      opacity: 0.8;
    }
    .form-wrap
    {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.product-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -20px;

  .product-icon {
    margin-right: 20px;
    margin-bottom: 20px;
    max-width: 150px;

    img {
      margin: auto;
      max-width: 100%;
    }
  }
}
