.ingredients-table-wrapper{
  padding-bottom: 30px;
  background-color: #F8F6EF;
  color: #444444;

  .ingredients-table{
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1.29px;
  }
  .underlined{
    border-bottom: 1px solid $borderColor;
  }

  thead th{
    padding: 30px;
  }

  tbody th, td{
    padding: 20px 30px 0 30px;
  }

  tbody tr:last-child th{
    // padding-bottom: 20px;
  }


  th{
    width: 50%;
  }

}
