.about,
.blog,
.poc {
	position: relative;
	width: 100%;
	height: auto;
	max-width: 1000px;
	margin: 0 auto;
	padding: 60px 0 30px;
	display: flex;
	flex-wrap: wrap;
	z-index: 1000;
	background-color: white;
	justify-content: space-between;
}

.blog {
  display: flex;
  margin-top: -65px;
  max-width: 750px;

  .blog-content {
    max-width: 750px;
  }
}

.blog {
  padding: 50px;

  .blog-content:first-child .content-block p {
    text-align: left;
    max-width: 100%;
  }

  .bloc-heading {
    margin: 0;
  }

  .sub-title {
    @extend .text-heading5;

    color: $darkerGray;
    font-weight: normal;
  }

  .image {
    margin: auto;
  }
}

.bloc-heading {
	width: 100%;
	@extend .text-heading3;
	text-align: center;

	color: #444444;
}
.about-content,
.blog-content
{
	 width: 100%;
  max-width: 540px;
	//width: 45%;
  text-align: left;
  letter-spacing: 0;
	margin: 0 auto 60px;

	@media all and (max-width: 1000px) {
		width: 100%;
		padding: 0 20px;
	}
	@media all and (max-width: 768px) {
		padding-bottom: 0px;
	}

	&.full-width{
		width: 100%;
    max-width: 100%;
	}

	.title {
		margin: 0 0 60px 0;
		font-family: $fontTitle;
		font-weight: bold;
		font-size: 42px;
		line-height: 53px;

		text-align: center;
	}

	.content-block {
		display: flex;
		flex-wrap: wrap;
    @extend .text-regular;
		color: $darkerGray;

		p:first-child {
			margin: 0 0 20px;
		}

		h1 {
			font-size: 15px;
			font-weight: 800;
		}

		h4{
			margin: 15px 0 0 0;
			display: inline-block;
			color: #7F5432;

			@media(max-width: 1000px){
				width: 97%;
			}
		}

		strong {
			color: $darkerGray;
			font-weight: bold;
		}

		p{
			margin: 0;
			display: inline-block;
		}

		img{
			width: 10px;
			padding-top: 21px;
			margin-right: 10px;

			@media(max-width: 1000px){
				display: none;
			}
		}
	}

	&:first-child {
		padding-bottom: 0;

		.content-block {
			column-count: 1;
			margin-bottom: 0;

			p {
				width: 100%;
				max-width: 800px;
				margin: 0 auto;
				text-align: center;
			}
		}
	}

	img {
		width: 100%;
		height: auto;
	}

	.videoWrapper {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;
	}
	.videoWrapper iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}


	@media all and (max-width: 425px) {
		.title {
			margin: 30px 0;
		}
	}
}

.poc-content {
  padding: 0 40px;
  max-width: 100%;

  .title {
    margin: 0 0 60px 0;
    font-family: $fontTitle;
    font-weight: bold;
    font-size: 42px;
    line-height: 53px;
  }

  .content-block {
    @extend .text-regular;
    color: $darkerGray;

    p:first-child {
      margin: 0 0 20px;
    }

    h1 {
      font-size: 15px;
      font-weight: 800;
    }

    h4{
      margin: 15px 0 0 0;
      display: inline-block;
      color: #7F5432;

      @media(max-width: 1000px){
        width: 97%;
      }
    }

    strong {
      color: $darkerGray;
      font-weight: bold;
    }

    p{
      //margin: 0;
      display: inline-block;
    }

    img{
      width: 10px;
      padding-top: 21px;
      margin-right: 10px;

      @media(max-width: 1000px){
        display: none;
      }
    }
  }
}

.image{
	max-width: 100%;

	img{
			max-width: 100%;
	}

	iframe{
  	max-width: 100%;
	}
}

.timeline {
	display: flex;
	flex-direction: column;
	margin: 0px 220px 70px 220px;

	@media all and (max-width: 1024px) {
		margin: 60px 100px 70px 100px;
	}

	@media all and (max-width: 620px) {
		margin: 60px 30px 70px 30px;
	}

	@media all and (max-width: 425px) {
		margin: 60px 10px 70px 10px;
	}
}

.timeline-element {
	display: flex;
	align-items: center;
	position: relative;

	width: calc(50% + (55px/2) );

	text-align: left;
	// background-color: gray;

	@media all and (max-width: 850px) {
		width: 100%;
	}

	.year {
		flex-shrink: 0;
		background-color: $colorAccent2;
		color: white;

    	min-width: 55px;
		width: 55px;
		height: 55px;

	    z-index: 2;
	    border-radius: 50%;
	    border: solid 4px white;

	    text-align: center;
	    font-size: 14px;
	    font-family: $fontJosefin;
	    line-height: 52px;

	    &.active {
	    	background-color: white;
	    	color: $colorAccent2;
	    	border: solid 4px $colorAccent2;
	    }

	}

	&:nth-child(odd){
		margin-left: calc(50% - (55px/2) );

		&:before {
			content: "";
		    top: 0;
		    position: absolute;
		    bottom: 0;
		    width: 4px;
		    background-color: $colorAccent2;
		    left: calc( (58px - (4px * 2)) / 2 );
		    z-index: 1;
		}

		.year {
			margin-right: 40px;
		}

		@media all and (max-width: 850px) {
			margin: 0;
		}

		@media all and (max-width: 425px) {
			.year {
		        margin-right: 20px;
		    }
		}

	}

	&:nth-child(even){
		justify-content: flex-end;
		text-align: right;

		&:after {
			content: "";
		    top: 0;
		    position: absolute;
		    bottom: 0;
		    width: 4px;
		    background-color: $colorAccent2;
		    right: calc( (60px - (4px * 2)) / 2 );
		    z-index: 1;


		}

		.year {
			order: 2;
			margin-left: 40px;
		}
		&:last-child {
		    &:after {
		    	height: 50%;
		    }
		}

		@media all and (max-width: 850px) {

			margin: 0;

			.timeline-content {
				text-align: left;
			}

			.year {
				order: 0;
				margin-left: 0;
				margin-right: 40px;
			}

			&:before {
				content: "";
			    top: 0;
			    position: absolute;
			    bottom: 0;
			    width: 4px;
			    background-color: $colorAccent2;
			    left: calc( (58px - (4px * 2)) / 2 );
			    z-index: 1;
			}
			&:after {
				display: none;
			}
		}
		@media all and (max-width: 425px) {
			.year {
		        margin-right: 20px;
		    }
		}

	}




}

.timeline-element:first-child{
	&:before {
		top:50%;
	}
}

.timeline-element:last-child{
	&:before {
		bottom:50%;
	}
}

.timeline-content {
	margin: 15px 0;
	.title {
		margin: 0 0 10px 0;
		font-size: 14px;
		font-family: $fontJosefin;
		font-weight: 500;
		text-transform: uppercase;
		line-height: 18px;
	}

	.content {
		font-size: 14px;
		font-family: $openSans;
		line-height: 22px;
	}
}
