.overlay-form
{
  width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200000;
    transition: 0.2s ease-in-out;
    visibility: hidden;

    .form-wrap
    {
      display: block;
      width: 380px;
      background-color: #fff;
      border-radius: 5px;
      text-align: center;
      padding: 45px 55px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -225px;
      margin-left: -190px;
      z-index: 10;
      transform: scale(0.7);
      opacity: 0;
      transition: all 0.3s;

      @media all and (max-width: 600px){
        width: 300px;
        margin-left: -150px;
        margin-top: -240px;
        padding: 35px;
      }

      img
      {
        width: 100%;
      }

      .close
      {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0;
        margin: 0;
        font-weight: 600;
        color: $colorAccent1;
        cursor: pointer;
        font-size: 20px;
      }
    }

    .overlay-filter
    {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 9;
      transition: 0.2s ease-in-out;
      background-color: $colorAccent1;
      opacity: 0;
    }

    &.active
    {
      visibility: visible;
      .overlay-filter
      {
        opacity: 0.8;
      }
      .form-wrap
      {
      opacity: 1;
      transform: scale(1);
      }
    }
}