.element-list {
  position: relative;
  display: block;
  width: calc(33.33333% - (47px / 3));
  margin: 0 23px 0px 0;
  min-height: 350px;
  text-decoration: none;

  @media (min-width: 2150px) {
    width: calc(25% - (70px / 4));
  }

  &:hover{
    cursor: pointer;
  }

  &:nth-child(3n+3) {
  	margin-right: 0;

    @media (min-width: 2150px) {
      margin-right: 23px
    }
  }

  &:nth-child(4n+4) {
    @media (min-width: 2150px) {
      margin-right: 0;
    }
  }

  @media(max-width: 1130px) {
  	width: calc(50% - (23px / 2));
  	margin-bottom: 30px;

  	&:nth-child(3n+3) {
	  	margin-right: 23px;
	  }

	  &:nth-child(2n+2) {
	  	margin-right: 0;
	  }

  }

  @media(max-width: 600px) {
  	width: 100%;
  	margin-right: 0;

  	&:nth-child(3n+3) {
	  	margin-right: 0;
	  }
  }

  .tag-wrapper {
    max-width: 385px;
    margin: auto;
    position: relative;
  }

  p.tag {
    position: absolute;
    top: 18px;
    left: -10px;
    width: 144px;
    min-height: 32px;
    text-align: center;
    padding: 7px 0 5px;
    z-index: 100;
    margin: 0;
    display: inline-block;

    @extend .text-subtitle;

    background-color: $colorGold;
    color: $colorAccent5;
    box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.25);

    &.new-tag {
      background-color: $colorGold;
      color: $colorAccent5;

      &:after {
        border-top: 16px solid $colorGold;
        border-bottom: 16px solid $colorGold;
        border-left: 7px solid $colorGold;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      left: 144px;
      bottom: 0;
      width: 0;
      border-right: 7px solid transparent;
      border-top: 16px solid $colorGold;
      border-bottom: 16px solid $colorGold;
      border-left: 7px solid $colorGold;
    }
  }

	header {
		width: 100%;
    max-width: 385px;
    height: 255px;
    margin: 0 auto;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
		// height: 75%;
		overflow: hidden;
 		position: relative;
    // background-color: $colorAccent5;
  	background-color: $colorAccent6;

	  @media(max-width: 1200px) {
	  	//height: 65%
	  }

	}

	.thumb {
    width: 100%;
		height: 100%;
		display: block;
	  object-fit: cover;
		transition: all 0.5s ease-in-out;
    background-size: cover;
    background-position: center;

    &:hover {
			transform: scale(1.1);
    		opacity: .8;
		}
	}


	.content {
		padding: 20px;
    color: #000;

    &.blog-card {
      .title3,
      .title4 {
        margin: 0;
      }
    }

		.title3 {
      @extend .text-heading5;
      /* identical to box height */

      margin-top: 0;
      text-align: center;
      color: $darkerGray;
		}

		.title4 {

      @extend .text-subtitle;
      /* identical to box height */
      margin: 0 0 10px;

      text-align: center;
      color: $darkGray;
		}
	}
}
