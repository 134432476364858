.bloc-services{

  background-color: #fff;
  position: relative;
  z-index: 1000;

  &.home-about {
    .main-text {
      text-align: justify;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    //padding: 50px 0;

    @media(max-width: 1023px){
      flex-wrap: wrap-reverse;
    }
  }

  .content {
    width: 50%;
    height: auto;
    display: flex;

    max-width: 500px;
    margin: auto;
    padding: 100px 0;

    @media (max-width: 1024px) {
      max-width: 100%;
      padding: 30px 0;
    }

    .wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;

      position: relative;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      max-width: 85%;
      background-color: #fff;

      @media screen and (max-width: 1330px) {
        // padding: 6vw 50px;
      }

      .main-text  {
        @extend .text-regular;
        color: $darkerGray;
      }

      .title3 {
        @extend .text-heading4;
        color: $darkerGray;
        margin: 0 0 30px;
      }

      .site-btn {
        margin: 20px 0 0;
      }
    }

  }

  .visual {
    width: 50%;
    height: auto;
  }

  .services-carousel {

    .carousel-cell{
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;

      /*img{
        display: block;
        width: 100%;
        height: auto;
      }*/
    }

    .flickity-prev-next-button{
      background: transparent;
    }

  }

  @media all and (max-width: 1499px) {
    .col.half.content{
      width: 50%;
    }
    .col.half.visual{
      width: 50%;
    }
  }

  @media all and (max-width: 1023px) {

    .col.half.content{
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      width: 100%;

      .wrap{
        padding: 40px 20px;
      }
    }

    .col.half.visual{
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      width: 100%;
      height: auto;
    }
  }

}
