.contact {
  position: relative;
  z-index: 1000;
  background-color: #fff;

  .coordo-wrap
  {
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 50px 25px;

    @extend %clearfix;


    .coordo
    {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-family: $fontJosefin;
      width: calc((100% - (30px * 2)) / 3);
      padding: 33px;
      border: 1px solid $lightGray;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      @media all and (max-width: 560px){
        width: 100%;
        margin-bottom: 25px;
      }

      img  {
        display: inline-block;
        margin-bottom: 34px;

        @media all and (max-width: 900px){
          max-width: 85px;
        }
      }
      a {
        display: inline-block;
        font-family: $fontText;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 180%;
        color: $colorGold;
      }
    }
    p
    {
      margin: 0;
    }
  }

  .map{
    position: relative;
    width: 100%;
    height: 40vw;
    min-width: 100%;
    max-width: 100%;
    max-height: 490px;
    // border-bottom: 5px solid #fff;

    z-index: 1;

    @media all and (max-width: 560px){
      height: 80vw;
    }
  }

}
