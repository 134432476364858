.pagination {
	position: relative;
  width: 100%;

  text-align: center;

  padding-top: 2vw;
  padding-bottom: 4vw;

  .current {
  	padding: 5px 10px;
	  background-color: $colorAccent4;
  	color: #fff;
  	border-radius: 30px;
  }


  a {
		padding: 8px;
		margin: 0 5px;
		font-family: $fontJosefin;
  	text-decoration: none;
  	color: #444444;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 14px;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 1px;


  	&:last-child {
  		margin-left: 20px;
  	}
  }

}
